<template>
  <v-card @dblclick="goToPoolSheet" class="fill-height">
    <v-overlay absolute :value="!editing && lowlight"></v-overlay>
    <v-container fluid :class="{'pb-0': !mini}">
      <!-- TITLE ROW/BUTTONS -->
      <v-row dense align="center" justify="space-between">
        <template v-if="editing && !holder && !isLeague">
          <v-col style="max-width: 50%;">
            <v-text-field
              label="Pool"
              v-model="pool.name"
              hide-details
              color="color3"
            ></v-text-field>
          </v-col>
          <v-col style="max-width: 50%;">
            <v-text-field
              label="Court"
              v-model="pool.courts"
              hide-details
              color="color3"
            ></v-text-field>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="12" class="font-weight-bold" v-if="holder">
            {{pool.name}}
          </v-col>
          <template v-else-if="!isLeague">
            <v-col cols="2" class="font-weight-bold">
              Pool {{pool.name}}
            </v-col>
            <v-col cols="10" class="font-weight-bold text-right" v-if="!holder">
              <span class="mr-3" v-if="pool.courts">Court {{pool.courts | formatArraySort}}</span>
              <unlock-dialog
                v-if="view.adminAndRefs && pool.locked && !tournament.locked"
                @unlock-click="onLockToggle"
                :inline="true"
                :warn="isInfluencer"
              ></unlock-dialog>
              <v-btn color="color3 color3Text--text" small fab class="ml-1" @click.stop="goToPoolSheet">
                <v-icon>fas fa-caret-right</v-icon>
              </v-btn>
            </v-col>
          </template>
        </template>
      </v-row>
      <v-divider></v-divider>
      <!-- PLAY START ALERT -->
      <v-row dense v-if="showStartTime && startTime">
        <v-col cols="12" class="pa-0">
          <v-alert type="info" :value="true" class="black--text ma-0" prominent text>
            <strong>Play starts {{startTime.format('dddd h:mm A')}}</strong>
          </v-alert>
        </v-col>
      </v-row>
      <!-- DUP COURT ALERT -->
      <v-row dense v-if="view.admin && tournament.props.includes('conflicts') && conflicts && conflicts.length">
        <v-col cols="12">
          <v-alert dismissable type="info" :value="true">
            This court is also being used by {{conflicts.map(c => `${c.division} Pool ${c.name}`) | formatArray}}
          </v-alert>
        </v-col>
      </v-row>
      <!-- LOCK ALERT -->
      <v-row dense v-if="view.admin && pool.complete && !pool.locked">
        <v-col cols="12">
          <v-alert type="warning" :value="true">
            <strong class="black--text">{{pool.name}} is not locked.{{pool.tbDone ? ' TB Done' : ''}}</strong>
            <v-btn
              @click.stop="onLockToggle"
              :loading="loading"
            >lock</v-btn>
          </v-alert>
          <div class="error--text" v-if="autoLock && !loading && !noAuto">
            Auto Lock in:
            <countdown
              :lengthInSeconds="60"
              :mini="true"
              @expired="onAutoLock"
            ></countdown>
            <v-btn x-small color="black" @click.stop="noAuto = true" text>cancel</v-btn>
          </div>
        </v-col>
      </v-row>
      <!-- STATUS -->
      <v-row dense justify="space-between" v-if="!sorting && !editing && !holder && pool.matches.length > 0">
        <v-col class="title pb-0" v-if="pool.status && hasRanks">
          {{pool.locked ? 'Final' : 'Current'}} Standings
        </v-col>
        <v-col class="pb-0" :class="{'text-right': $vuetify.breakpoint.smAndUp}" v-if="!pool.locked">
          {{`${pool.completeMatches.length} of ${pool.matches.length} matches complete`}}
        </v-col>
      </v-row>
      <!-- LIVE SCORES -->
      <v-row dense v-if="!sorting && !editing && !holder && !nolive && (pool.ongoingMatches && pool.ongoingMatches.length > 0)">
        <v-col cols="12" v-for="m in pool.ongoingMatches" :key="m.id" class="text-right py-0">
          <v-chip outlined color="success" small width="100%">
            <v-icon small class="mr-2">fas fa-signal-stream</v-icon> Match {{m.number}}{{m.games && m.games.length > 1 ? ` | Game ${m.currentGame.number}` : ''}}: {{m.currentGame.home || 0}} - {{m.currentGame.away || 0}}
          </v-chip>
        </v-col>
      </v-row>
      <v-row dense v-if="myTeams.length === 0 && !holder">
        <v-col cols="12" class="pa-0">
          <v-alert type="error" :value="true" class="black--text ma-0" prominent text>
            <strong>We are still waiting on some results before this pool can be filled</strong>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
    <!-- TEAM LIST - BEFORE MATCHES PLAYED || SORTING -->
    <v-list :three-line="threeLine" :two-line="!threeLine" class="pool" v-if="(!pool.status && !mini) || sorting">
      <v-list-item v-for="(team) in myTeams" :key="team.teamId" class="team" :id="team.teamId">
        <v-overlay absolute :value="!editing && !lowlight && highlight && highlight !== team.teamId" color="white" :opacity=".8"></v-overlay>
        <v-list-item-avatar v-if="sorting" class="sortHandle">
          <v-icon>fas fa-bars</v-icon>
        </v-list-item-avatar>
        <!-- SLOT DOT -->
        <v-list-item-avatar class="mx-0 mr-2">
          <v-avatar :color="teamColorsInverse[team.slot - 1]" :size="$vuetify.breakpoint.smAndDown ? '36' : '36'">
            <span class="white--text" v-if="!holder">{{team.slot}}</span>
          </v-avatar>
        </v-list-item-avatar>
        <!-- LOGO -->
        <v-list-item-action class="mx-1" v-if="team.logo">
          <v-img
            :src="team.logo"
            max-height="48"
            max-width="48"
            contain
          ></v-img>
        </v-list-item-action>
        <!-- SEED -->
        <v-list-item-content class="seed" v-if="!hideSeeds && !threeLine">
          <v-list-item-subtitle class="seed" :class="{sm: $vuetify.breakpoint.smAndDown}">{{team.seed}}</v-list-item-subtitle>
          <v-list-item-subtitle :key="division.repField" v-if="team.repping && !noRep" :class="{sm: $vuetify.breakpoint.smAndDown}">&nbsp;</v-list-item-subtitle>
          <v-list-item-subtitle v-if="team.adminRep && threeLine" :class="{sm: $vuetify.breakpoint.smAndDown}">&nbsp;</v-list-item-subtitle>
        </v-list-item-content>
        <!-- PLAYERS NAME -->
        <v-list-item-content
          v-if="team.logo && team.players && ((team.players.length === 2 && division.isDoubles) || (team.players.length === 1 && division.isSingles))"
          :class="{
            sm: $vuetify.breakpoint.smAndDown,
            clickable: !editing,
            'color3--text': !editing
          }"
          @click.stop="teamClick(team.teamId)"
        >
          <v-list-item-title :class="{sm: $vuetify.breakpoint.smAndDown}">{{team.players[0] ? team.players[0].fullname : null}} / {{team.players[1] ? team.players[1].fullname : null}}</v-list-item-title>
          <v-list-item-subtitle :class="{sm: $vuetify.breakpoint.smAndDown}">
            {{team.name}}
          </v-list-item-subtitle>
          <v-list-item-subtitle :class="{sm: $vuetify.breakpoint.smAndDown}" v-if="threeLine">
            {{team.adminRep}}
          </v-list-item-subtitle>
        </v-list-item-content>
        <!-- TEAM NAME -->
        <v-list-item-content :class="{sm: $vuetify.breakpoint.smAndDown}" v-else>
          <v-list-item-title :class="{sm: $vuetify.breakpoint.smAndDown}">{{team.name}}</v-list-item-title>
          <v-list-item-subtitle class="text-truncate" :key="division.repField" v-if="!noRep" :class="{sm: $vuetify.breakpoint.smAndDown}">{{team.repping}}</v-list-item-subtitle>
          <v-list-item-subtitle :class="{sm: $vuetify.breakpoint.smAndDown}" v-if="threeLine">{{team.adminRep}}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action :class="{sm: $vuetify.breakpoint.smAndDown}">
          <v-list-item-action-text>{{ `${team.matchWins}-${team.matchLosses}` }}</v-list-item-action-text>
          <v-list-item-action-text v-if="sorting && false">
            <v-btn color="color3" text icon x-small @click.stop="swapMe(team)">
              <v-icon>fas fa-exchange</v-icon>
            </v-btn>
          </v-list-item-action-text>
        </v-list-item-action>
      </v-list-item>
      <v-list-item :class="{hide: !sorting || pool.teams.length > 0}">

      </v-list-item>
    </v-list>
    <!-- TEAM LIST - AFTER MATCHES STARTED -->
    <v-list two-line class="pool" v-else-if="!mini">
      <v-list-item v-for="(team) in rankedTeams" :key="team.teamId" class="team">
        <v-overlay absolute :value="!editing && !lowlight && highlight && highlight !== team.teamId" color="white" :opacity=".8"></v-overlay>
        <!-- SLOT DOT -->
        <v-list-item-avatar class="mr-2 ml-0">
          <v-avatar :color="teamColorsInverse[team.slot - 1]" size="40" class="white--text">
            <v-icon v-if="team.forfeit" color="white">fas fa-medkit</v-icon>
            <span class="white--text" v-if="!team.rank">{{team.slot}}</span>
            <template v-else-if="team.rank < 99">
              <span class="headline">{{team.rank}}</span>
              <sup>{{team.rank | ordinalSuffix}}</sup>
            </template>
          </v-avatar>
        </v-list-item-avatar>
        <!-- LOGO -->
        <v-list-item-action class="mx-1" v-if="team.logo">
          <v-img
            :src="team.logo"
            height="48"
            max-width="48"
            contain
          ></v-img>
        </v-list-item-action>
        <!-- SEED -->
        <v-list-item-content class="seed" v-if="!hideSeeds && !threeLine">
          <v-list-item-subtitle class="seed">{{team.seed}}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="team.repping && !noRep" :key="division.repField" class="white--text hidden">x</v-list-item-subtitle>
        </v-list-item-content>
        <!-- PLAYERS NAME -->
        <v-list-item-content
          v-if="team.logo && team.players && team.players.length === 2"
          :class="{
            sm: $vuetify.breakpoint.smAndDown,
            clickable: !editing,
            'color3--text': !editing
          }"
          @click.stop="teamClick(team.teamId)"
        >
          <v-list-item-title :class="{sm: $vuetify.breakpoint.smAndDown}">{{team.players[0] ? team.players[0].fullname : null}} / {{team.players[1] ? team.players[1].fullname : null}}</v-list-item-title>
          <v-list-item-subtitle :class="{sm: $vuetify.breakpoint.smAndDown}">
            {{team.name}}
          </v-list-item-subtitle>
          <v-list-item-subtitle :class="{sm: $vuetify.breakpoint.smAndDown}" v-if="threeLine">
            {{team.adminRep}}
          </v-list-item-subtitle>
        </v-list-item-content>
        <!-- TEAM NAME -->
        <v-list-item-content v-else>
          <v-list-item-title class="color3--text clickable" @click.stop="teamClick(team.teamId)">
            {{team.name}}
          </v-list-item-title>
          <v-list-item-subtitle :key="division.repField" v-if="!noRep">{{team.repping}}</v-list-item-subtitle>
          <v-list-item-subtitle :class="{sm: $vuetify.breakpoint.smAndDown}" v-if="threeLine">{{team.adminRep}}</v-list-item-subtitle>
        </v-list-item-content>
        <!-- W/L RECORD -->
        <v-list-item-action>
          <v-list-item-action-text v-if="team.forfeit">forfeit</v-list-item-action-text>
          <v-list-item-action-text v-else-if="pool.isDuals">{{ `${team.dualWins || 0}-${team.dualLosses || 0}` }}</v-list-item-action-text>
          <v-list-item-action-text v-else>{{ `${team.matchWins || 0}-${team.matchLosses || 0}` }}</v-list-item-action-text>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <!-- VIDEO BUTTON -->
    <v-row dense v-if="videoFab">
      <v-col class="text-right" cols="12" >
        <v-btn
          color="color3"
          text
          :href="videoFab.url"
          target="_blank"
        >
          <v-icon class="mr-4">{{videoFab.icon}}</v-icon>
          {{videoFab.text}}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import firstBy from 'thenby'
import { mapGetters } from 'vuex'
import RouteMixin from '@/Mixins/RouteMixin'
import flatten from '@/helpers/ArrayFlatten'
import RoundMixin from '@/Mixins/RoundMixin'
import moment from 'moment'
const UnlockDialog = () => import('@/components/Tournament/Pools/LockToggle.vue')
const Countdown = () => import('@/components/Utils/Countdown')

export default {
  props: ['pool', 'sorting', 'editing', 'holder', 'lowlight', 'highlight', 'showStartTime', 'nolive', 'mini', 'isLeague'],
  mixins: [RouteMixin, RoundMixin],
  data () {
    return {
      loading: false,
      noAuto: false
    }
  },
  computed: {
    ...mapGetters(['getTeam', 'view', 'teamColorsInverse', 'getDivision', 'tournament', 'getRound']),
    autoLock () {
      return this.tournament.autoLock
    },
    startTime () {
      const st = this.pool.startTimeCalc || moment(this.round.dtStart)
      const dtStartL = moment(st.format().replace('Z', this.division.location.offset))
      return st && dtStartL.isAfter() ? st : null
    },
    poolMap () {
      return flatten(this.tournament.divisions.map(d => {
        return d.pools.map(p => {
          return {
            division: d.name,
            id: p.id,
            name: p.name,
            courts: p.courts ? p.courts.split(',') : []
          }
        })
      }))
    },
    useLogos () {
      return this.division && this.division.useLogos
    },
    myCourts () {
      return this.pool.courts.split(',')
    },
    conflicts () {
      return this.poolMap.filter(f => f.id !== this.pool.id && f.courts.filter(c => this.myCourts.includes(c)).length) || []
    },
    division () {
      return this.divisionId ? this.getDivision(this.divisionId) : this.tournament.divisions.find(f => f.pools.find(f => f.id === this.pool.id))
    },
    round () {
      return this.dayId ? this.getRound(this.dayId) : this.tournament.rounds.find(f => f.pools.find(f => f.id === this.pool.id))
    },
    rankedTeams () {
      const teams = this.pool.teams.map(t => {
        var x = this.getTeam(t.teamId)
        var e = this.pool.locked && !t.summary
        const s = this.round.number === 1 ? x.seed : t.roundSeed || x.seed
        const fp = t.fromPool ? ` (${t.fromPool})` : ''
        return {
          teamId: x.id,
          rank: e ? 'NA' : this.pool.locked ? t.summary.finish : t.rank,
          seed: s,
          repping: x.getRep(this.division.repField),
          adminRep: x.field ? `Field: ${x.field}` : `${t.adminRep}${fp}`,
          name: x.name,
          dualWins: e ? 'NA' : this.pool.locked ? t.summary.dualWins : t.dualWins,
          dualLosses: e ? 'NA' : this.pool.locked ? t.summary.dualLosses : t.dualLosses,
          matchWins: e ? 'NA' : this.pool.locked ? t.summary.matchWins : t.matchWins,
          matchLosses: e ? 'NA' : this.pool.locked ? t.summary.matchLosses : t.matchLosses,
          slot: t.slot,
          forfeit: t.forfeit,
          logo: this.useLogos && x.logo,
          players: this.useLogos && x.players
        }
      })
      return teams.sort(firstBy('rank'))
    },
    repField () {
      return this.division.repField
    },
    hasRanks () {
      return !!this.rankedTeams.find(f => f.rank)
    },
    myTeams () {
      if (!this.division) return []
      const teams = this.division.repField && this.pool.teams.map(t => {
        const x = this.getTeam(t.teamId)
        const s = this.round.number === 1 ? x.seed : t.roundSeed || x.seed
        const fp = t.fromPool ? ` (${t.fromPool})` : ''
        return {
          teamId: x.id,
          slot: t.slot,
          seed: s,
          repping: x.getRep(this.division.repField),
          // adminRep: x.field || t.adminRep,
          adminRep: x.field ? `Field: ${x.field}` : `${t.adminRep}${fp}`,
          name: x.name,
          matchWins: t.matchWins,
          matchLosses: t.matchLosses,
          forfeit: t.forfeit,
          logo: this.useLogos && x.logo,
          players: this.useLogos && x.players
        }
      })
      return teams.sort(firstBy('slot'))
    },
    dirty () {
      return this.pool.dirty
    },
    a_stat () {
      return this.pool.status
    },
    threeLine () {
      return this.view.admin && (!!this.myTeams.find(f => f.adminRep) || (this.division && this.division.isNcaa))
    },
    videoFab () {
      const courts = this.pool.courts ? this.pool.courts.split(' ') : []
      return courts.length && this.tournament.fabs.find(f => f.type === 'court' && f.courtName === courts[0])
    },
    lockDTO () {
      return this.pool.teams.map(team => {
        return {
          id: team.id,
          summary: {
            finish: team.rank,
            dualWins: team.dualWins,
            dualLosses: team.dualLosses,
            matchWins: team.matchWins,
            matchLosses: team.matchLosses,
            setWins: team.setWins,
            setLosses: team.setLosses,
            pointsFor: team.pointsFor,
            pointsAgainst: team.pointsAgainst
          }
        }
      })
    },
    noRep () {
      return this.division && (this.division.props.includes('noRep') || this.division.isDuals || this.useLogos)
    },
    isInfluencer () {
      return !!this.tournament.selectors.filter(f => f.fromRoundId === this.roundId).length
    }
  },
  methods: {
    teamClick (id) {
      if (this.editing) return
      this.$router.push({ name: 'division-team', params: { tournamentId: this.tournament.id, teamId: id } })
    },
    add (i, team) {
      team.poolId = this.pool.id
      this.pool.teams.splice(i, 0, team)
      this.reSlot()
    },
    remove (i) {
      const team = this.pool.teams.splice(i, 1)[0]
      this.reSlot()
      return team
    },
    reSlot () {
      let i = 1
      this.pool.teams.forEach(team => {
        team.slot = i++
      })
    },
    goToPoolSheet () {
      if (!this.holder && !this.editing) {
        this.$router.push({ name: 'pool-sheet', params: { divisionId: this.division.id, dayId: this.round.id, poolId: this.pool.id } })
      }
    },
    onAutoLock () {
      if (!this.loading && !this.pool.locked) this.onLockToggle()
    },
    onLockToggle () {
      this.loading = true
      const dto = {
        locked: !this.pool.locked,
        teams: this.lockDTO
      }
      this.pool.patch(dto)
        .then(() => {
          this.pool.locked = dto.locked
          dto.teams.forEach(t => {
            const team = this.pool.teams.find(f => f.id === t.id)
            team.summary = t.summary
          })
          this.loading = false
        })
    },
    swapMe (team) {
      this.swappable = team
      this.dialog = true
    }
  },
  components: {
    UnlockDialog,
    Countdown
  }
}
</script>

<style scoped>
.seed {
  max-width: 20px;
  min-width: 20px;
  text-align: end;
  font-size: smaller;
  white-space: nowrap;
}
.draggable-mirror {
  background-color: #fff;
  border: 1px solid var(--v-color2-base);
  z-index: 999;
}
.draggable-mirror >>>.v-list__tile {
  height: inherit;
}
.draggable-mirror >>>.v-list__tile .v-list__tile__sub-title {
  color: rgba(0,0,0,0.54);
}
.draggable--over {
  background-color: var(--v-color3-base) !important;
  color: var(--v-color3Text-base) !important;
}
.draggable-source--is-dragging {
  background-color: #eee;
}
.draggable-source--is-dragging >>> div {
  visibility: hidden;
}
.hide {
  display: none;
}
.hidden {
  opacity: 0;
}
.v-list__tile__avatar {
  min-width: 40px !important;
}
.sm {
  font-size: small !important;
}
.sm.seed {
  margin-top: 2px;
  font-size: smaller !important;
}
.v-list-item__content.sm {
  align-self: center !important;
}
</style>
